



















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    service: {
      type: Object,
      required: true,
    },
    current: {
      typpe: Object,
      required: true,
    },
  },
});
