
















































































import { computed, defineComponent, ref, watch, watchEffect } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { range } from 'lodash';
import Content from '@/components/Content.vue';
import { NavModule } from '@/store/api/model';
import UserForm from '@/components/Form/index.vue';
import ServiceItem from './ServiceItem.vue';

export default defineComponent({
  components: { Content, UserForm, ServiceItem },
  setup(_, { root }) {
    const main = useMainStore();
    const modules = computed(() => main.servicesModules);

    const current = ref<NavModule>();

    watch(
      () => [modules.value, root.$route.params.id],
      () => {
        if (root.$route.params.id) {
          for (const m of modules.value) {
            for (const item of m.children) {
              if (item.id.toString() === root.$route.params.id.toString()) {
                current.value = item;
                return;
              }
            }
          }
        } else {
          current.value = modules.value?.[0]?.children[0];
        }
      },
      { immediate: true },
    );

    return { modules, current };
  },
});

// export default defineComponent({
//   components: { Content, UserForm },
//   setup(_, { root }) {
//     const main = useMainStore();

//     const key = ref(0);
//     const currentIndex = ref('');

//     const modules = computed(() => main.toolkitModules);

//     const defaultOpen = computed(() => modules.value.map(m => m.id.toString()));

//     const current = ref<NavModule>();
//     // watchEffect(() => {
//     //   console.log(root.$route.params.id);
//     // });

//     watch(
//       () => [modules.value, root.$route.params.id],
//       () => {
//         if (modules.value && modules.value[0] && modules.value[0].children?.[0]) {
//           if (root.$route.params.id) {
//             for (const m of modules.value) {
//               for (const item of m.children) {
//                 if (item.id.toString() === root.$route.params.id.toString()) {
//                   current.value = item;
//                   currentIndex.value = item.id.toString();
//                   return;
//                 }
//               }
//             }
//             current.value = modules.value[0].children[0];
//             currentIndex.value = current.value.id.toString();
//           } else {
//             current.value = modules.value[0].children[0];
//             currentIndex.value = current.value.id.toString();
//           }
//         }
//       },
//       { immediate: true },
//     );

//     return {
//       key,
//       modules,
//       defaultOpen,
//       current,
//       currentIndex,
//       onTabSelect: (id: string) => {
//         for (const m of modules.value) {
//           for (const item of m.children) {
//             if (item.id.toString() === id) {
//               current.value = item;
//               currentIndex.value = item.id.toString();
//             }
//           }
//         }
//       },
//     };
//   },
// });
